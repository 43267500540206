import React, { useState, useEffect, useRef } from "react";
import {
  AppShell,
  Checkbox,
  Title,
  Group,
  Badge,
  Button,
  Burger,
  Center,
  Stack,
  ScrollArea,
} from "@mantine/core";
import FantasyCalendar from './FantasyCalendar';
import { FaTrophy } from "react-icons/fa";
import { GrPrevious, GrNext } from "react-icons/gr";
import { PiBugBeetleFill } from "react-icons/pi";
import { useDisclosure } from "@mantine/hooks";
import "@mantine/core/styles.css";

// Import your JSON guide data
import guideDataJson from "./metaphor_guide.json";

function App() {
  const [guideData] = useState(guideDataJson);
  const [opened, { toggle }] = useDisclosure();
  const [completedTasks, setCompletedTasks] = useState(() => {
    const savedProgress = localStorage.getItem('completedTasks');
    return savedProgress ? JSON.parse(savedProgress) : {};
  });

  const dates = Object.keys(guideData.calendar);

  const [currentDay, setCurrentDay] = useState(() => {
    const savedCurrentDay = localStorage.getItem('currentDay');
    if (savedCurrentDay) {
      return savedCurrentDay;
    }
    for (let date of dates) {
      if (
        guideData.calendar[date].some((timeOfDay) =>
          timeOfDay.tasks.some((task) => !completedTasks[task.id])
        )
      ) {
        return date;
      }
    }
    return dates[0];
  });

  const stackRef = useRef(null);
  const [scrollAreaHeight, setScrollAreaHeight] = useState(0);

  useEffect(() => {
    const calculateHeight = () => {
      if (stackRef.current) {
        setScrollAreaHeight(`calc(100vh - ${(stackRef.current.offsetHeight || 200) + 60 + 60 + 24}px)`);
      }
    };
    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, [stackRef]);

  // Load the current day from localStorage when it changes
  useEffect(() => {
    localStorage.setItem('currentDay', currentDay);
  }, [currentDay]);

  // Save the completed tasks to localStorage whenever they are updated
  useEffect(() => {
    localStorage.setItem('completedTasks', JSON.stringify(completedTasks));
  }, [completedTasks]);

  const toggleTaskCompletion = (taskId) => {
    setCompletedTasks((prevTasks) => ({
      ...prevTasks,
      [taskId]: !prevTasks[taskId],
    }));
  };

  const goToNextDay = () => {
    const currentIndex = dates.indexOf(currentDay);
    if (currentIndex < dates.length - 1) {
      setCurrentDay(dates[currentIndex + 1]);
    }
  };

  const goToPreviousDay = () => {
    const currentIndex = dates.indexOf(currentDay);
    if (currentIndex > 0) {
      setCurrentDay(dates[currentIndex - 1]);
    }
  };

  const renderTrophyBadge = (trophies) => {
    return (
      <Badge color="teal" size="xl">
        <FaTrophy /> {trophies.length}
      </Badge>
    );
  };

  const renderGoldBeetleBadge = (goldBeetles) => {
    return (
      <Badge color="yellow" size="xl">
        <PiBugBeetleFill /> {goldBeetles.length}
      </Badge>
    );
  };

  return (
    <AppShell
      padding="md"
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <span>Metaphor Tracker</span>
        </Group>
      </AppShell.Header>

      <AppShell.Navbar p="md">
        <FantasyCalendar
          guideData={guideData}
          currentDay={currentDay}
          setCurrentDay={setCurrentDay}
          completedTasks={completedTasks}
          toggleNavbar={toggle}
        />
      </AppShell.Navbar>

      <AppShell.Main>
        <Stack
          ref={stackRef}
          align="stretch"
          justify="flex-start"
          gap="sm"
          mb={24}
          p={18}
          styles={(theme) => ({
            root: {
              backgroundColor: theme.colors.dark[8],
              borderRadius: "10px",
              boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.25)"
            },
          })}
        >
          <Title order={4} align="center" mb={12}>
            Current Date
          </Title>
          <Group grow preventGrowOverflow={false} wrap="nowrap" mb={12} justify="center">
            <Button
              size="md"
              onClick={goToPreviousDay}
              disabled={currentDay === dates[0]}
            >
              <GrPrevious />
            </Button>
            <Center>
              <Title>{currentDay}</Title>
            </Center>
            <Button
              size="md"
              onClick={goToNextDay}
              disabled={
                currentDay === dates.slice(-1)[0]
              }
            >
              <GrNext />
            </Button>
          </Group>
          <Group justify="center" spacing="sm" grow>
            {renderTrophyBadge(guideData.calendar[currentDay].flatMap((timeOfDay) => timeOfDay.trophies))}
            {renderGoldBeetleBadge(
              guideData.calendar[currentDay].flatMap((timeOfDay) => timeOfDay.goldBeetles)
            )}
          </Group>
        </Stack>

        <ScrollArea
          style={{
            height: scrollAreaHeight,
          }}
        >
          {guideData.calendar[currentDay].map((timeOfDay, timeOfDayIdx) => (
            <div key={timeOfDayIdx}>
              <Title order={3} my={12}>
                {timeOfDay.timeOfDay}
              </Title>
              {timeOfDay.tasks.map((task) => (
                <Checkbox
                  key={task.id}
                  my={12}
                  size="lg"
                  styles={{ inner: { marginTop: "1px" } }}
                  label={
                    <>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: task.task.replace(
                            /%PUBLIC_URL%/g,
                            process.env.PUBLIC_URL
                          ),
                        }}
                      />
                      {task.location && (
                        <span style={{ display: 'block', fontStyle: 'italic' }}>
                          {task.location}
                        </span>
                      )}
                    </>
                  }
                  checked={!!completedTasks[task.id]}
                  onChange={() => toggleTaskCompletion(task.id)}
                />
              ))}
            </div>
          ))}
        </ScrollArea>
      </AppShell.Main>

      <AppShell.Footer height={60} p="xs">
        <Group justify="end">Powered by Keith</Group>
      </AppShell.Footer>
    </AppShell>
  );
}

export default App;
