import React, { useState, useMemo } from 'react';
import { Group, Button, Stack, Text } from "@mantine/core";
import { GrPrevious, GrNext } from "react-icons/gr";

function FantasyCalendar({ guideData, currentDay, setCurrentDay, completedTasks, toggleNavbar }) {
  const [currentMonth, setCurrentMonth] = useState(() => {
    if (currentDay) {
      return parseInt(currentDay.split('/')[0], 10) - 1;
    }
    return 5;
  });
  const daysOfWeek = ["Flamesday", "Watersday", "Arboursday", "Metalsday", "Idlesday"];
  const months = [
    "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"
  ];

  const completedDates = useMemo(() => {
    const updatedCompletedDates = {};
    for (const date in guideData.calendar) {
      updatedCompletedDates[date] = guideData.calendar[date].every((timeOfDay) =>
        timeOfDay.tasks.every((task) => completedTasks[task.id])
      );
    }
    return updatedCompletedDates;
  }, [guideData, completedTasks]);

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => Math.max(prev - 1, 0));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => Math.min(prev + 1, 11));
  };

  const renderCalendarGrid = useMemo(() => {
    const daysInMonth = currentMonth === 11 ? 35 : 30;
    const calendarDays = [];

    // Fill in actual days of the month
    for (let day = 1; day <= daysInMonth; day++) {
      const formattedDate = `${String(currentMonth + 1).padStart(2, '0')}/${String(day).padStart(2, '0')}`;
      const isDisabled = !guideData.calendar[formattedDate];
      const allTasksComplete = completedDates[formattedDate];
      const isCurrentDay = formattedDate === currentDay;

      const color = allTasksComplete ? 'green' : 'red';

      calendarDays.push(
        <Button
          key={formattedDate}
          variant={isCurrentDay ? 'filled' : 'outline'}
          color={color}
          onClick={() => { setCurrentDay(formattedDate); if (typeof toggleNavbar === 'function') toggleNavbar(); }}
          disabled={isDisabled}
          size="lg"
          style={{ padding: '0' }}
          styles={{ label: { alignItems: "center" } }}
        >
          {day}
        </Button>
      );
    }

    return calendarDays;
  }, [currentMonth, guideData, currentDay, completedDates, setCurrentDay, toggleNavbar]);

  return (
    <Stack>
      <Group position="apart" grow mb="md">
        <Button onClick={handlePreviousMonth} size="xs" variant="subtle" disabled={currentMonth === 0}>
          <GrPrevious />
        </Button>
        <Text size='xl' fw={700} ta='center'>{`${months[currentMonth]}`}</Text>
        <Button onClick={handleNextMonth} size="xs" variant="subtle" disabled={currentMonth === 11}>
          <GrNext />
        </Button>
      </Group>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '4px', textAlign: 'center', fontWeight: 'bold' }}>
        {daysOfWeek.map((day, index) => (
          <div key={index}>{day.charAt(0)}</div>
        ))}
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: '4px' }}>{renderCalendarGrid}</div>
    </Stack>
  );
}

export default FantasyCalendar;
